import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import CaseLogo from "../../components/caseLogo";
import { CaseList } from "../../components/caseList.en";

const References = () => {
  const images = useStaticQuery(graphql`
    query {
      gasag: file(relativePath: { eq: "logos/gasag.png" }) {
        publicURL
      }
      gkv: file(relativePath: { eq: "logos/gkv.png" }) {
        publicURL
      }
      amazone: file(relativePath: { eq: "logos/amazone.png" }) {
        publicURL
      }
      messemuenchen: file(relativePath: { eq: "logos/messe-muenchen.png" }) {
        publicURL
      }
      ssi: file(relativePath: { eq: "logos/ssi.png" }) {
        publicURL
      }
      helaba: file(relativePath: { eq: "logos/helaba.png" }) {
        publicURL
      }
      mercedesbenz: file(relativePath: { eq: "logos/mercedes-benz.png" }) {
        publicURL
      }
      schmotzer: file(relativePath: { eq: "logos/schmotzer.png" }) {
        publicURL
      }
      boots: file(relativePath: { eq: "logos/boots.png" }) {
        publicURL
      }
      bosch: file(relativePath: { eq: "logos/bosch.png" }) {
        publicURL
      }
      tchibo: file(relativePath: { eq: "logos/tchibo.png" }) {
        publicURL
      }
      bitmarck: file(relativePath: { eq: "logos/bitmarck.png" }) {
        publicURL
      }
      nuernberger: file(relativePath: { eq: "logos/nuernberger.png" }) {
        publicURL
      }
      nergie: file(relativePath: { eq: "logos/n-ergie.png" }) {
        publicURL
      }
      liebherr: file(relativePath: { eq: "logos/liebherr.png" }) {
        publicURL
      }
      hagebau: file(relativePath: { eq: "logos/hagebau.png" }) {
        publicURL
      }
      dmdrogeriemarkt: file(
        relativePath: { eq: "logos/dm-drogeriemarkt.png" }
      ) {
        publicURL
      }
      ottogroup: file(relativePath: { eq: "logos/otto-group.png" }) {
        publicURL
      }
      guardian: file(relativePath: { eq: "logos/guardian.png" }) {
        publicURL
      }
      tui: file(relativePath: { eq: "logos/tui.png" }) {
        publicURL
      }
      blume2000: file(relativePath: { eq: "logos/blume2000.png" }) {
        publicURL
      }
      fressnapf: file(relativePath: { eq: "logos/fressnapf.png" }) {
        publicURL
      }
      soskinderdorf: file(relativePath: { eq: "logos/sos-kinderdorf.png" }) {
        publicURL
      }
      aachenmuenchener: file(
        relativePath: { eq: "logos/aachenmuenchener.png" }
      ) {
        publicURL
      }
      astrazeneca: file(relativePath: { eq: "logos/astrazeneca.png" }) {
        publicURL
      }
      mhplus: file(relativePath: { eq: "logos/mhplus.png" }) {
        publicURL
      }
      swissinfo: file(relativePath: { eq: "logos/swissinfo.png" }) {
        publicURL
      }
      haufe: file(relativePath: { eq: "logos/haufe.png" }) {
        publicURL
      }
    }
  `);

  return (
    <Layout lang="en" langPath="nav.references.link">
      <Seo
        title="References – Trust that we enjoy"
        description="As a partner, we accompany our customers throughout the entire project cycle and continuously inspire with the mix of professional competence and family flair."
        keywords="References, Case studies, projects, customers, partners"
      />
      <div className="parallax-translate bg-shape-diamond"></div>
      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-12">
            <h1 className="h5 text-color-monday-green">References</h1>
            <h2 className="text-size-2 text-size-md-1">Trust that we enjoy</h2>
            <p>
              Impressing our customers with solutions for personalization, brand
              consistency and improvements to efficiency is the easy part. But
              there are other reasons why our national and international
              enterprise clients have entrusted the long-standing development of
              their digital platforms to Monday Consulting. First and foremost
              are our experience and our collaborative approach to tackling a
              customer project.
            </p>
            <p>
              Every project is a chance to become better.
              <br />
              Here are just some of the ways in which we’ve grown.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-4 mb-md-5">
        <div className="row mb-3 reveal">
          <h2 className="col-12 text-center">Our latest case studies</h2>
        </div>
        <CaseList />
      </div>

      <div className="container">
        <div className="row mb-3 reveal">
          <h2 className="col-12 text-center">Other references</h2>
        </div>

        <div className="row">
          {Object.values(images).map((image, i) => (
            <CaseLogo key={i} {...{ image }} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default References;
