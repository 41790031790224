import React from "react";
import { object, oneOfType, string } from "prop-types";

const CaseLogo = ({ image }) => {
  return (
    <div className="col-6 col-md-4 col-lg-3">
      <div className="logo-card reveal p-2 mb-3 bg-white">
        <img className="grayscale" src={image.publicURL} alt="Logo" />
      </div>
    </div>
  );
};

CaseLogo.propTypes = {
  image: oneOfType([object, string]),
};

export default CaseLogo;
